import React from "react";
import { graphql } from "gatsby";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { GatsbyImage } from "gatsby-plugin-image"
import CTA from "../Cards/CTA";
import SEO from "../SEO";
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from "react-share"
import "./Blog.scss"
import RelatedPosts from "./RelatedPosts";

export default function BlogPost(props) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const post = props.data.markdownRemark;
  const url = "https://www.studentmottagningen.se" + props.path;
  const image = post.frontmatter.coverImage?.childImageSharp.gatsbyImageData;

  const others = props.data.blog.posts;
  
  //get sharable image
  const shareImage = image?.images.fallback.src;

  const schema = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": post.frontmatter.title,
    "image": [
      shareImage
      ],
    "datePublished": new Date(post.frontmatter.date).toISOString(),
    "author": [{
        "@type": "Organization",
        "name": "Studentmottagningen",
        "url": "https://www.studentmottagningen.se"
      }
    ]
  }

  //return component based on category
  function returnComponent() {
    
    console.log(post.frontmatter)
    if(post.frontmatter.category === "studentfest") {
      return <CTA header="Köpt allt inför studentfesten?" subHeader="Skapa en minnesvärd fest med vackra dekorationer och tillbehör." link="https://click.adrecord.com/?c=155221&p=665&url=https://www.partyhallen.se/studenten" />
    }
    else if(post.frontmatter.category === "studentskylt") {
      return <CTA header="Inte beställt studentskylt än?" subHeader="Designa och beställ din studentskylt under 5 min." link="https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/studenten/studentskylt" />

    }
    else if(post.frontmatter.category === "studentbal-tjejer") {
      return <CTA header="På jakt efter balklänning?" subHeader="Hitta din drömbalklänning hos Bubbleroom - stort utbud till bra priser" link="https://pin.bubbleroom.se/t/t?a=462891025&as=1773817588&t=2&tk=1&url=https://www.bubbleroom.se/sv/kl%C3%A4der/kvinna/balkl%C3%A4nningar" />
    }
    else if(post.frontmatter.category === "studentinbjudan") {
      return <CTA header="Dags att fixa studentinbjudan?" subHeader="Designa och beställ dina inbjudningkort under 5 min." link="https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/produkter/designselection.aspx?pcg=cards.large&occ=student&purpose=invitation" />
    }
    else if(post.frontmatter.category === "tackkort") {
      return <CTA header="Dags att skicka tackkort?" subHeader="Designa och beställ dina tackkort under 5 min." link="https://to.smartphoto.se/t/t?a=1065483635&as=1691974914&t=2&tk=1&url=https://www.smartphoto.se/fotokort" />
    }
  }

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        article={true}
        description={post.frontmatter.description}
        image={image?.images.fallback.src}
        schema={schema}
      />
      <Navbar />
      {post.frontmatter.links && 
      <div className="py-3 bg-gray-50"><p className="flex max-w-screen-xl mx-auto items-center px-4 sm:px-8 justify-between w-full text-gray-700 text-sm md:text-base">Artikeln innehåller reklam genom annonslänkar.</p></div>
      }
      <div className="container px-5 py-2 mx-auto">
        
        <article className="blogPost">
        {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt={post.frontmatter.altText} />}
          <div>
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-4 mt-2">{post.frontmatter.title}</h1>
            <small className="text-md	font-medium mb-3">
              Studentmottagningen,{" "}
              {post.frontmatter.date &&
                new Date(post.frontmatter.date).toLocaleDateString('sv-SV', options)}
            </small>
            <br />
            <div dangerouslySetInnerHTML={{ __html: post.html }} className="blogText mt-3" />
            {returnComponent()}
            <br />
            <div className="shareButtons">
              <FacebookShareButton url={url} className="share">
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton url={url} title={post.frontmatter.title} className="share">
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
            </div>
            <br />
            <br />
          </div>
        </article>
        <RelatedPosts posts={others} dateOptions={options}/>
        <br/>
      </div>
      <Footer dark={true} />
    </>
  );
}
export const query = graphql`
  query BlogQuery($slug: String!, $skip: Int) {
    markdownRemark(fields: { slug: { eq: $slug } } ) {
      html
      frontmatter {
        title
        date
        description
        category
        links
        altText
        coverImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    },
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC},
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" } }
      skip: $skip,
      limit: 3,
    ) {
      posts: nodes {
        frontmatter {
          date
          category
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED,width:500)
            }
          }
        }
        id
        fields {
          slug
        }
      }
    }
  }
`;
